import { useEffect, useState } from 'react';
import TextForm from '../TextForm/textForm';
import DropdownSelect from '../DropdownSelect/dropdownSelect';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { levels, rooms } from '../RoomMatrix';
import { IHistoryData, ISlotsData } from '../../../UI/Interfaces';
import { buttonStyle, buttonStyleSend } from '../../Page.jss';
import { useSummaryData } from '../useSummaryData';
import './reservationForm.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { StyledTableCell } from '../TableCalendar/tableCalendar.tss';

interface ReservationFormProps {
    slotsData: ISlotsData[];
    historyData: IHistoryData[];
    setHistoryData: (value: IHistoryData[]) => void;
    setShowSuccessAlert: (value: boolean) => void;
    setFutureOccupiedSlots: (value: boolean) => void;
    setShowConflictAlert: (value: boolean) => void;
    showDeleteSuccessAlert: boolean;
    fetchData: () => void;
    reservationSlot: string;
    date: string;
    setShowFormWindow: (value: boolean) => void;
    setShowSummary: (value: boolean) => void;
    onConfirmNewSelection: () => void;
    setShowMissingDataAlert: (value: boolean) => void;
}

const ReservationForm = ({
    historyData,
    setShowSuccessAlert,
    setShowConflictAlert,
    fetchData,
    reservationSlot,
    date,
    setShowFormWindow,
    setShowSummary,
    onConfirmNewSelection,
    setShowMissingDataAlert
}: ReservationFormProps) => {
    const {
        startTime,
        setStartTime,
        setName,
        setSurname,
        setPlateNumber,
        setEndTime,
        endTime,
        selectedLevel,
        setSelectedLevel,
        selectedRoom,
        setSelectedRoom,
        setSlot,
        onSubmit,
        isToday
    } = useSummaryData({
        historyData,
        setShowConflictAlert,
        fetchData,
        setShowSuccessAlert,
        setShowFormWindow,
        setShowSummary,
        setShowMissingDataAlert
    });

    const [endTimeCheck, setEndTimeCheck] = useState(dayjs(startTime).add(24, 'hour'));
    const [levelRooms, setLevelRooms] = useState([]);

    const createLevelRooms = (level: string) => {
        return rooms.map((room) => ({
            value: `${level}${room.value}`,
            label: `${level}${room.label}`
        }));
    };

    useEffect(() => {
        if (selectedLevel !== null) {
            //@ts-ignore
            setLevelRooms(createLevelRooms(selectedLevel));
            //@ts-ignore
            setSelectedRoom(createLevelRooms(selectedLevel)[0]['value']);
        }
    }, [selectedLevel]);

    useEffect(() => {
        setEndTime(dayjs(startTime).add(24, 'hour'));
    }, [startTime]);

    useEffect(() => {
        const currentDate = dayjs(date, 'DD.MM.YYYY');
        const currentTime = dayjs();
        const combinedDateTime = currentDate
            .hour(currentTime.hour())
            .minute(currentTime.minute())
            .second(0);

        setSlot(reservationSlot);
        setStartTime(combinedDateTime);
    }, [reservationSlot, date]);

    const handleTimeChange = (value: Dayjs | null) => {
        if (value) {
            const currentDate = dayjs(date, 'DD.MM.YYYY');
            const updatedDate = currentDate.hour(value.hour()).minute(value.minute()).second(0);
            setStartTime(updatedDate);
        }
    };

    const formItems = [
        {
            label: 'Jméno',
            component: <TextForm onChange={(value) => setName(value)} title={''} />
        },
        {
            label: 'Příjmení',
            component: <TextForm onChange={(value) => setSurname(value)} title={''} />
        },
        {
            label: 'Patro pokoje',
            component: (
                <div style={{ width: '100%' }}>
                    <DropdownSelect
                        initialValue={selectedLevel}
                        onChange={(value) => setSelectedLevel(value.value)}
                        options={levels}
                        className="selectElevation"
                        title={''}
                    />
                </div>
            )
        },
        {
            label: 'Pokoj',
            component:
                selectedLevel !== null ? (
                    <DropdownSelect
                        initialValue={selectedRoom}
                        onChange={(value) => setSelectedRoom(value.value)}
                        options={levelRooms}
                        className="selectElevation"
                        title={''}
                    />
                ) : (
                    <p>Vyberte prosím patro</p>
                )
        },
        {
            label: 'SPZ',
            component: (
                <TextForm
                    onChange={(value) => setPlateNumber(value)}
                    useUpperCase={true}
                    title={''}
                />
            )
        },
        {
            label: 'Začátek rezervace ',
            component: (
                <div className="time-input-style">
                    <p>{isToday}</p>
                    {isToday && (
                        <TimePicker
                            ampm={false}
                            value={startTime}
                            onChange={handleTimeChange}
                            disablePast={false}
                            minTime={dayjs()}
                        />
                    )}
                    {!isToday && (
                        <TimePicker
                            ampm={false}
                            value={startTime}
                            onChange={handleTimeChange}
                            disablePast={false}
                        />
                    )}
                </div>
            )
        },
        {
            label: 'Konec rezervace',
            component: (
                <div className="time-input-style">
                    <DateTimePicker
                        label=""
                        value={dayjs(endTime) ?? new Date()}
                        onChange={(newValue) => setEndTime(dayjs(newValue) ?? null)}
                        format="DD/MM/YYYY HH:mm"
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                        }}
                        minDate={dayjs(endTimeCheck)}
                        minTime={dayjs(endTimeCheck)}
                    />
                </div>
            )
        }
    ];

    return (
        <div className="content-container">
            <h2> Rezervace pro </h2>
            <h3> parkovací místo: {reservationSlot === '32' ? 'R99' : reservationSlot}</h3>
            <h3> na den: {date}</h3>
            <TableContainer component={Paper}>
                <Table sx={{ maxWidth: 500 }} aria-label="customized table">
                    <TableBody>
                        {formItems.map((item, index) => (
                            <TableRow key={index}>
                                <StyledTableCell>{item.label} </StyledTableCell>
                                <StyledTableCell className="input-style">
                                    {item.component}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="input-style">
                <button onClick={() => onConfirmNewSelection()} style={buttonStyle}>
                    <p style={{ color: 'white' }}>Zpátky na přehled</p>
                </button>
                <button onClick={() => onSubmit()} style={buttonStyleSend}>
                    <p style={{ color: 'white' }}>Vytvořit rezervaci</p>
                </button>
            </div>
        </div>
    );
};

export default ReservationForm;
